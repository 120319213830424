var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "counter" }, [
    _c("h1", { staticClass: "heading" }, [_vm._v("Counter")]),
    _c("h3", { staticClass: "sub-heading" }, [
      _vm._v("Vuex stored counter: " + _vm._s(_vm.counter))
    ]),
    _c("p", { staticClass: "counter__buttons" }, [
      _c(
        "button",
        {
          staticClass: "button button--02",
          on: { click: _vm.incrementCounter }
        },
        [_vm._v(" Increment counter ")]
      ),
      _c(
        "button",
        {
          staticClass: "button button--02",
          attrs: { disabled: _vm.counter <= 0 },
          on: { click: _vm.decrementCounter }
        },
        [_vm._v(" Decrement counter ")]
      ),
      _c(
        "button",
        {
          staticClass: "button button--01",
          attrs: { disabled: _vm.counter == 0 },
          on: { click: _vm.confirmCounterReset }
        },
        [_vm._v(" Reset counter ")]
      )
    ]),
    _c("hr", { staticClass: "hr" }),
    _c("p", { staticClass: "paragraph" }, [
      _vm._v("Testing additional computed properties besides mapGetters")
    ]),
    _c("p", { staticClass: "paragraph" }, [
      _vm._v("FullName: " + _vm._s(_vm.fullName))
    ]),
    _c("p", { staticClass: "paragraph" }, [
      _c(
        "button",
        {
          staticClass: "button button--02",
          attrs: { disabled: _vm.nameChanged },
          on: { click: _vm.confirmChangeName }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.nameChanged ? "Name was already changed" : "Change name"
              ) +
              " "
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }